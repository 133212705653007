.logo{
    margin-top: 1rem;
    margin-left: 5rem;
  }

.links {
  float: right;
  margin-right: 3rem;
}


@media screen and (max-width: 768px){
    .logo {
      width: auto;
      margin-left: -18.7rem;
    }

    .links {
      float: left;
      margin-top: 26rem;
    }
}


