body {
  background: #222f3e;
  line-height: 1.5;
}
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: "Roboto Mono", monospace;
}


