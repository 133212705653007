.Title {
    position: absolute;
    margin-left: 8vh;
    margin-top: 30vh;
    color: white;
  }
  p {
    width: 100vh;
  }
  h1 {
    font-weight: 700;
    font-size: 8vh;
    letter-spacing: -0.0416666667rem;
    line-height: 1;
    margin: 0 0 0.6666666667rem;
    padding-top: 0.6666666667rem;
    color: white;
    font-family: "Russo One", sans-serif;
  }
  .gif {
    position: absolute;
    margin-left: 73rem;
    margin-top: 30vh;
  }

  .middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 27vh;
    margin-left: -38vh;
  }
  
  .btn {
    position: relative;
    display: block;
    color: white;
    font-size: 14px;
    font-family: "montserrat";
    text-decoration: none;
    margin: 30px 0;
    border: 2px solid black;
    padding: 14px 60px;
    text-transform: uppercase;
    overflow: hidden;
    transition: 1s all ease;
  }
  .btn::before {
    background: black;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.6s ease;
  }
  
  .btn4::before {
    width: 100%;
    height: 0%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .btn4:hover::before {
    height: 380%;
  }
  
  @media screen and (max-width: 768px){
    h1 {
      font-size: 1.5rem;
    }
    h2 {
      font-size: 1rem;
      width: auto;
    }
    p {
      font-size: 0.8rem;
      width: auto;
    }

    .Title {
      margin-top: 7rem ;
      margin-left: 0.5rem;
      width: auto;
    }
    .middle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin-top: 46.8vh;
      margin-left: -1rem;
    }
    
    .btn {
      position: relative;
      display: block;
      color: white;
      font-size: 12px;
      font-family: "montserrat";
      text-decoration: none;
      margin: 30px 0;
      border: 2px solid black;
      padding: 12px 28px;
      text-transform: uppercase;
      overflow: hidden;
      transition: 1s all ease;
    }
    .btn::before {
      background: black;
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
      transition: all 0.6s ease;
    }
    
    .btn4::before {
      width: 100%;
      height: 0%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    .btn4:hover::before {
      height: 380%;
    }
    
    .gif {
      display: none;
    }
  }